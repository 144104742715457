<template>
    <div>
        <!--Create Vehicle Modal-->
        <b-modal no-close-on-backdrop scrollable  title="Create Vehicle" class="modal-dark" v-model="createVehicle">
            <b-row>
                <b-col sm="4">
                    <b-form-group>
                        <label>Make</label>
                        <b-form-input v-model="newVehicle.make"/>
                    </b-form-group>
                </b-col>
                <b-col sm="4">
                    <b-form-group>
                        <label>Model</label>
                        <b-form-input v-model="newVehicle.model"/>
                    </b-form-group>
                </b-col>
                <b-col sm="4">
                    <b-form-group>
                        <label>Year</label>
                        <b-form-input type="number" v-model.number="newVehicle.year"/>
                    </b-form-group>
                </b-col>
                <b-col sm="4">
                    <b-form-group>
                        <label>License Plate</label>
                        <b-form-input v-model="newVehicle.plate"/>
                    </b-form-group>
                </b-col>
                <b-col sm="4">
                    <b-form-group>
                        <label>Color</label>
                        <b-form-input v-model="newVehicle.color"/>
                    </b-form-group>
                </b-col>
                <b-col sm="12">
                    <b-form-group>
                        <label>Description</label>
                        <b-form-textarea v-model="newVehicle.description"/>
                    </b-form-group>
                </b-col>
                <b-col sm="12">
                    <b-form-group>
                        <label>Notes</label>&nbsp;
                        <fa-icon :icon="['fas', 'circle-plus']" style="cursor: pointer;" @click="push(newVehicle.notes, fields.notes[0])"/>
                        <b-input-group v-for="(e,i) in newVehicle.notes" :key="i"  v-if="refresh">
                            <b-form-input v-model="newVehicle.notes[i].text" />
                            <b-input-group-append v-if="e.length > 1">
                                <b-button variant="dark" @click="splice(i, newVehicle.notes)">
                                    <fa-icon :icon="['fas', 'close']"/>
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col sm="12">
                    <b-form-group>
                        <label>Documents</label>&nbsp;
                        <fa-icon :icon="['fas', 'circle-plus']" style="cursor: pointer;" @click="push(newVehicle.documents, fields.documents[0])"/>
                        <b-input-group v-for="(e,i) in newVehicle.documents" :key="i"  v-if="refresh">
                            <b-form-input v-model="newVehicle.documents[i].description" />
                            <b-input-group-append v-if="e.length > 1">
                                <b-button variant="dark" @click="splice(i, newVehicle.documents)">
                                    <fa-icon :icon="['fas', 'close']"/>
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <template slot="modal-footer">
                <b-button size="sm" variant="secondary" @click="createVehicle=false">Cancel</b-button>
                <b-button size="sm" variant="primary" @click="postVehicle(newVehicle)">Submit</b-button>
            </template>
        </b-modal>

        <!-- Vehicle edit Modal -->
        <b-modal no-close-on-backdrop scrollable  title="Edit Vehicle" class="modal-dark" id="vehicle-modal" size="lg" v-model="modalOpen">
            <b-row>
                <b-col sm="4">
                    <b-form-group>
                        <label>Make</label>
                        <b-form-input v-model="openVehicle.make"/>
                    </b-form-group>
                </b-col>
                <b-col sm="4">
                    <b-form-group>
                        <label>Model</label>
                        <b-form-input v-model="openVehicle.model"/>
                    </b-form-group>
                </b-col>
                <b-col sm="4">
                    <b-form-group>
                        <label>Year</label>
                        <b-form-input type="number" v-model.number="openVehicle.year"/>
                    </b-form-group>
                </b-col>
                <b-col sm="4">
                    <b-form-group>
                        <label>License Plate</label>
                        <b-form-input v-model="openVehicle.plate"/>
                    </b-form-group>
                </b-col>
                <b-col sm="4">
                    <b-form-group>
                        <label>Color</label>
                        <b-form-input v-model="openVehicle.color"/>
                    </b-form-group>
                </b-col>
                <b-col sm="12">
                    <b-form-group>
                        <label>Description</label>
                        <b-form-textarea v-model="openVehicle.description"/>
                    </b-form-group>
                </b-col>
                <b-col sm="12">
                    <b-form-group>
                        <label>Notes</label>&nbsp;\
                        <fa-icon :icon="['fas', 'circle-plus']" style="cursor: pointer;" @click="push(openVehicle.notes, fields.notes[0])"/>
                        <b-input-group v-for="(e,i) in openVehicle.notes" :key="i"  v-if="refresh">
                            <b-form-input v-model="openVehicle.notes[i].text" />
                            <b-input-group-append v-if="e.length > 1">
                                <b-button variant="dark" @click="splice(i, openVehicle.notes)">
                                    <fa-icon :icon="['fas', 'close']"/>
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col sm="12">
                    <b-form-group>
                        <label>Documents</label>&nbsp;
                        <fa-icon :icon="['fas', 'circle-plus']" style="cursor: pointer;" @click="push(openVehicle.documents, fields.documents[0])"/>
                        <b-input-group v-for="(e,i) in openVehicle.documents" :key="i"  v-if="refresh">
                            <b-form-input v-model="openVehicle.documents[i].description" />
                            <b-input-group-append v-if="e.length > 1">
                                <b-button variant="dark" @click="splice(i, openVehicle.documents)">
                                    <fa-icon :icon="['fas', 'close']"/>
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <template slot="modal-footer">
                <b-button size="sm" variant="secondary" @click="modalOpen=false">Cancel</b-button>
                <b-button size="sm" variant="primary" @click="putVehicle(openVehicle)">Submit</b-button>
            </template>
        </b-modal>

        <!-- Vehicle show Modal -->
        <b-modal no-close-on-backdrop scrollable  title="Vehicle" class="modal-dark" id="vehicle-modal" size="lg" v-model="modalSOpen">
            <b-row>
                <b-col sm="12">
                    <label>Make: {{ showVehicle.make }}</label>
                </b-col>
                <b-col sm="12">
                        <label>Model: {{ showVehicle.model }}</label>
                </b-col>
                <b-col sm="12">
                        <label>Year: {{ showVehicle.year }}</label>
                </b-col>
                <b-col sm="12">
                        <label>License Plate: {{ showVehicle.plate }}</label>
                </b-col>
                <b-col sm="12">
                        <label>Color: {{ showVehicle.color }}</label>
                </b-col>
                <b-col sm="12">
                        <label>Description: {{ showVehicle.description }}</label>
                </b-col>
                <b-col sm="12">
                    <label>Notes:</label>
                    <ul>
                        <li v-for="(note, id) in showVehicle.notes" :key="id">{{ note.text }}</li>
                    </ul>
                </b-col>
                <b-col sm="12">
                    <label>Documents:</label>
                    <ul>
                        <li v-for="(doc, id) in showVehicle.documents" :key="id">{{ doc.description }}</li>
                    </ul>
                </b-col>
            </b-row>
            <template slot="modal-footer">
                <b-button size="sm" variant="secondary" @click="modalSOpen=false">Cancel</b-button>
            </template>
        </b-modal>

        <!-- Vehicle Table -->
        <b-card style="width: 100%; height: 100%">
            <div slot="header">
                <span class="d-flex w-100 justify-content-between">
                    <h5 style="margin-bottom: 0"><b>Vehicle Manager</b></h5>
                    <fa-icon :icon="['fas', 'square-plus']" size="lg" style="float: right; cursor: pointer;" @click="openCreateVehicle"/>
                </span>
            </div>
            <v-client-table @row-click="rowClicked" ref="dTable" style="width: 100%" :columns="columns" :data="vehicles" :options="tOptions" :theme="theme" class="dataTable">
                <div slot="make" slot-scope="props">
                    <span v-if="props.row.make !== null">{{props.row.make}}</span>
                    <span v-else>XX</span>
                </div>
                <div slot="model" slot-scope="props">
                    <span v-if="props.row.model !== null">{{props.row.model}}</span>
                    <span v-else>XX</span>
                </div>
                <div slot="year" slot-scope="props">
                    <span v-if="props.row.year !== null">{{props.row.year}}</span>
                    <span v-else>XX</span>
                </div>
                <div slot="plate" slot-scope="props">
                    <span v-if="props.row.plate !== null">{{props.row.plate}}</span>
                    <span v-else>XX</span>
                </div>
                <div slot="color" slot-scope="props">
                    <span v-if="props.row.color !== null">{{props.row.color}}</span>
                    <span v-else>XX</span>
                </div>
                <div slot="description" slot-scope="props">
                    <span v-if="props.row.description !== null">{{props.row.description}}</span>
                    <span v-else>XX</span>
                </div>
                <div slot="info" slot-scope="props">
                    <div style="float: right; cursor: pointer">
                        <fa-icon :icon="['fas', 'info']" size="lg" @click="openSModal(props.row)"/>&nbsp;
                        <fa-icon :icon="['fas', 'pen-to-square']" size="lg" @click="openEModal(props.row)"/>
                    </div>
                </div>
                <div slot="h__info">
                    <span style="float: right; margin-bottom: 0">Info/Edit</span>
                </div>
            </v-client-table>
            <b-tooltip v-if="tooltipRender" ref="tooltip" :target="target" :title="tooltipTitle" triggers=""></b-tooltip>
        </b-card>
    </div>
</template>

<script>
import Vue from 'vue'
import risc from '@/services/risc.js'
import { ClientTable } from 'vue-tables-2'

Vue.use(ClientTable)

export default {
    data() {
        return {
            columns: ['make', 'model', 'year', 'plate', 'color', 'description', 'info'],
            createVehicle: false,
            fields: {
                profiles: [],
                cases: [],
                make: '',
                model: '',
                year: 0,
                plate: '',
                color: '',
                description: '',
                notes: [
                    { text: ''}
                ],
                documents: [
                    { description: ''}
                ]
            },
            modalOpen: false,
            modalSOpen: false,
            newVehicle: {},
            openVehicle: {},
            showVehicle: {},
            refresh: true,
            sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
            tooltipTitle:"",
            tooltipRender:true,
            target: '',
            theme: 'bootstrap4',
            tOptions: {
                sortable: ['make', 'model', 'year', 'plate', 'color', 'description'],
                filterable: ['make', 'model', 'year', 'plate', 'color', 'description'],
                orderBy: {column: 'name', ascending: true},
                headings: {
                    make: 'Make',
                    model: 'Model',
                    year: 'Year',
                    plate: 'LP',
                    color: 'Color',
                    description: 'Desc',
                    info: 'Info/Edit'
                },
                sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                pagination: {
                    chunk: 5,
                    edge: false,
                    nav: 'scroll'
                },
                perPage: 25,
                skin: 'table table-striped table-hover',
            },
            vehicles: []
        }
    },
    methods: {
        getVehicles() {
            risc.getVehicles().then(response => {
                this.vehicles = response.data
            })
        },
        rowClicked(item) {
            //ctrl click to make tooltip appear with id
            if (item.event.ctrlKey) {
                this.tooltipRender = false;
                this.tooltipTitle = item.row._id;
                item.event.target.id = item.row._id;
                this.target = item.row._id;
                //restart tooltip so it can map to its new position
                Vue.nextTick().then(() => {
                    this.tooltipRender = true;
                    Vue.nextTick().then(() => {
                        this.$refs.tooltip.$emit('open')
                        setTimeout(function () {
                            //make the tooltip disappear automatically
                            this.$refs.tooltip.$emit('close');
                        }.bind(this), 4000)
                    });
                });
            }
        },
        openCreateVehicle(){
            this.newVehicle = {};
            this.newVehicle = JSON.parse(JSON.stringify(this.fields))
            this.createVehicle = true;
        },
        openEModal(info) {
            risc.getVehicle(info.vehicleId).then(response => {
                this.openVehicle = response.data
            })
            this.openVehicle = JSON.parse(JSON.stringify(info))
            Object.keys(this.fields).forEach(field => {
                if(this.openVehicle[field] === undefined) {
                    this.openVehicle[field] = JSON.parse(JSON.stringify(this.fields[field]))
                }
                else if(Array.isArray(this.openVehicle[field]) && this.openVehicle[field].length === 0) {
                    this.openVehicle[field] = JSON.parse(JSON.stringify(this.fields[field]))
                }
            })
            this.modalOpen = true
        },
        openSModal(info) {
            this.showVehicle = JSON.parse(JSON.stringify(info))
            risc.getVehicle(info.vehicleId).then(response => {
                this.showVehicle = response.data
            })
            this.modalSOpen = true
        },
        postVehicle(){
            this.createVehicle = false;
            Object.keys(this.fields).forEach(field => {
                if(JSON.stringify(this.newVehicle[field]) === JSON.stringify(this.fields[field]) || this.newVehicle[field] === undefined){
                    delete this.newVehicle[field];
                }
            })
            risc.postVehicle(this.newVehicle).then(() => {
                this.getVehicles();
            })
        },
        putVehicle() {
            this.modalOpen = false
            risc.putVehicle(this.openVehicle).then(() => {
                this.getVehicles()
            })
        },
        push(field, item){
            field.push(JSON.parse(JSON.stringify(item)));
            this.refresh=false;
            this.refresh=true;
        },
        splice(index, field) {
            field.splice(index, 1);
            this.refresh=false;
            this.refresh=true;
        }
    },
    created() {
        this.getVehicles()
    }
}
</script>